import request from '@/utils/admin/request'

export function login(username, password, code, uuid) {
    return request({
        url: 'auth/login-no-code',
        method: 'post',
        data: {
            username,
            password,
            code,
            uuid
        }
    })
}

export function auth(code) {
    return request({
        url: '/api/wechat/auth',
        method: 'get',
        data: code,
    })
}

export function getInfo() {
    return request({
        url: 'auth/info',
        method: 'get'
    })
}

export function getCodeImg() {
    return request({
        url: 'auth/code',
        method: 'get'
    })
}

export function logout() {
    return request({
        url: 'auth/logout',
        method: 'delete'
    })
}

export function addUser(object) {
    return request({
        url: 'charge-account/add-user',
        method: 'post',
        data: object,
    })
}

export function getAccountUsers() {
    return request({
        url: 'api/charge-account/users',
        method: 'get'
    });
}

export function getAccountOrder(userId) {
    return request({
        url: '/charge-account?userId=' + userId,
        method: 'get'
    });
}


export function addAccount(object) {
    return request({
        url: '/charge-account',
        method: 'post',
        data: object
    });
}

export function getAccountList(data) {
    return request({
        url: '/charge-order',
        method: 'get',
        params: data
    });
}

export function getAccountOrder4SelectList(data) {
    return request({
        url: '/charge-order/select',
        method: 'get',
        params: data
    });
}

export function getAccountOrderInfo(orderId) {
    return request({
        url: '/charge-order/info/' + orderId,
        method: 'get',
    });
}

export function getAccountOrderInfoCustom(orderId) {
    return request({
        url: '/charge-order/info-custom/' + orderId,
        method: 'get',
    });
}

export function getAccountOrderById(orderId) {
    return request({
        url: '/charge-order/' + orderId,
        method: 'get',
    });
}

export function deleteAccountOrder(orderId) {
    return request({
        url: '/charge-order/' + orderId,
        method: 'delete',
    });
}

export function editOrder(data) {
    return request({
        url: '/charge-account',
        method: 'put',
        data: data
    });
}

export function getAnalysis() {
    return request({
        url: '/charge-account/analysis',
        method: 'get'
    });
}

export function getAccountProducts(data) {
    return request({
        url: '/charge-account/products',
        method: 'get',
        params: data
    });
}

export function payForOrder(data) {
    return request({
        url: '/charge-account/payment-order-debt',
        method: 'post',
        data: data
    });
}

export function getDebtPage(data) {
    return request({
        url: '/charge-account/page-debt-merge',
        method: 'get',
        params: data
    });
}

export function getDebtInfo(debtId) {
    return request({
        url: '/charge-debt/info/' + debtId,
        method: 'get',
    });
}

export function payForDebt(debtId, data) {
    return request({
        url: '/charge-debt/pay/' + debtId,
        method: 'post',
        data: data
    });
}

export function listDebtMerge(userId) {
    return request({
        url: '/charge-debt/list-debt-merge/' + userId,
        method: 'get',
    });
}

export function mergeDebt(ids, data) {
    return request({
        url: '/charge-debt/merge-order-debt/' + ids,
        method: 'post',
        data: data
    });
}

export function deleteAccountDebt(debtId) {
    return request({
        url: '/charge-debt/' + debtId,
        method: 'delete',
    });
}

export function rollback(debtId) {
    return request({
        url: '/charge-debt/roll-back/' + debtId,
        method: 'put',
    });
}

export function rollbackSingle(debtId) {
    return request({
        url: '/charge-debt/roll-back/single/' + debtId,
        method: 'put',
    });
}

export function listAccountOrderTimeline(orderId) {
    return request({
        url: '/charge-order/timeline/' + orderId,
        method: 'get',
    });
}

export function deletePayTimeline(timelineId) {
    return request({
        url: '/charge-debt/pay-timeline/' + timelineId,
        method: 'delete',
    });
}
